<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {}
  },
  mounted() {
    const url = this.$route?.query?.url
    if (url) {
      window.location.replace(url)
    } else {
      this.$router.push('/')
    }
  },
}
</script>

<style>

</style>
